export const tradeTypeDict = {'D': 'Inter-dealer', 
                              'P': 'Bid Side',    // 'Purchase from Customer'
                              'S': 'Offered Side'}    // 'Sale to Customer'

export const statesDict = {
  'AK': 'Alaska',
  'AL': 'Alabama',
  'AR': 'Arkansas',
  'AS': 'American Samoa',
  'AZ': 'Arizona',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DC': 'District of Columbia',
  'DE': 'Delaware',
  'FL': 'Florida',
  'GA': 'Georgia',
  'GU': 'Guam',
  'HI': 'Hawaii',
  'IA': 'Iowa',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'MA': 'Massachusetts',
  'MD': 'Maryland',
  'ME': 'Maine',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MO': 'Missouri',
  'MP': 'Northern Mariana Islands',
  'MS': 'Mississippi',
  'MT': 'Montana',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'NE': 'Nebraska',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NV': 'Nevada',
  'NY': 'New York',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VA': 'Virginia',
  'VI': 'Virgin Islands',
  'VT': 'Vermont',
  'WA': 'Washington',
  'WI': 'Wisconsin',
  'WV': 'West Virginia',
  'WY': 'Wyoming'
}

export const purposeClassDict = {
  0: '', 
  1: 'Authority',
  2: 'Bond Anticipation Note',
  3: 'Building',
  4: 'Capital Loan Notes',
  5: 'Certificates Of Participation',
  6: 'Corporate Purpose',
  7: 'Drainage District',
  8: 'Economic Development Revenue',
  9: 'Education',
  10: 'Environment',
  11: 'Equipment',
  12: 'Federal Aid Note',
  13: 'Fire District',
  14: 'Flood Water Sewer Disposal',
  15: 'General Purpose',
  16: 'Grant Anticipation Note',
  17: 'Health',
  18: 'Housing',
  19: 'Improvement',
  20: 'Industrial Development Revenue',
  21: 'Library District',
  22: 'Metropolitan District',
  23: 'Miscellaneous',
  24: 'Municipal Bond Bank',
  25: 'Municipal Utilities District',
  26: 'Park District',
  27: 'Parking Authority',
  28: 'Pollution Control Revenue',
  29: 'Pool Financing Authority',
  30: 'Promissory Note',
  31: 'Public Housing Authority',
  32: 'Public Utility District',
  33: 'Racetrack/Casino',
  34: 'Recreation Authority',
  35: 'Revenue Anticipation Note',
  36: 'Sanitation District',
  37: 'School District',
  38: 'Special Assessment',
  39: 'Special Obligation',
  40: 'State and Federal Anticipation Note',
  41: 'State and Grant Anticipation Note',
  42: 'State Anticipation Notes',
  43: 'State Store',
  44: 'Tax and Revenue Anticipation',
  45: 'Tax Anticipation Note',
  46: 'Tax Revenue',
  47: 'Temporary Notes',
  48: 'Transportation',
  49: 'Tribal Bonds',
  50: 'Utility',
  51: 'Various Purpose',
  52: 'Veterans',
  53: 'Warrants'
}

export const subClassDict = {
  1: 'None', 
  2: 'Acute Care', 
  3: 'Air Transport', 
  4: 'Aircraft (Mnfc/Supplies)', 
  5: 'Airline/Airport', 
  6: 'Assisted Living', 
  7: 'Atmosphere/Space', 
  8: 'Authority', 
  9: 'Automotive (Mnfc/Supplies)', 
  10: 'Bridge and Toll Road', 
  11: 'Building', 
  12: 'Building Supplies', 
  13: 'Bus', 
  14: 'Cement', 
  15: 'Chemical', 
  16: 'College', 
  17: 'Combined', 
  18: 'Computer (Mnfc/Supplies)', 
  19: 'Construction', 
  20: 'Continuing Care Retirement Center', 
  21: 'Convention Center', 
  22: 'Correctional Facility', 
  23: 'Delinquent (Property Tax)', 
  24: 'Development', 
  25: 'District', 
  26: 'Economic Development', 
  27: 'Education', 
  28: 'Electric', 
  29: 'Energy Conservation', 
  30: 'Environment', 
  31: 'Equipment', 
  32: 'Excise Tax (Sales Beer Luxury)', 
  33: 'Facility', 
  34: 'Financial', 
  35: 'Fire', 
  36: 'Flood', 
  37: 'Food Products', 
  38: 'Franchise Tax', 
  39: 'Freight Forwarding', 
  40: 'Furniture', 
  41: 'Gaming', 
  42: 'General Utility', 
  43: 'Golf', 
  44: 'Harbor/Channel', 
  45: 'Health', 
  46: 'Highway (No Toll Is Paid)', 
  47: 'Hospital', 
  48: 'Hospital System Bonds', 
  49: 'Hotel/Motel/Inn', 
  50: 'Housing', 
  51: 'Improvement', 
  52: 'Industrial Development', 
  53: 'Lease', 
  54: 'Life Care', 
  55: 'Liquor (Ohio)', 
  56: 'Lumber', 
  57: 'Mall', 
  58: 'Manufacturing', 
  59: 'Marine Terminal', 
  60: 'Mass Transit', 
  61: 'Metal (Mnfc/Supplies)', 
  62: 'Mining', 
  63: 'Motor', 
  64: 'Multi', 
  65: 'Municipal', 
  66: 'Natural Gas', 
  67: 'Non-Profit', 
  68: 'Nuclear', 
  69: 'Nursing Home', 
  70: 'Office', 
  71: 'Oil/Gas', 
  72: 'Osteopathic', 
  73: 'Paper/Forest Products', 
  74: 'Parking Authority/Garage', 
  75: 'Pharmaceutical', 
  76: 'Plastics', 
  77: 'Pollution Control', 
  78: 'Pooled Financing', 
  79: 'Port Authority', 
  80: 'Power', 
  81: 'Preservation/Conservation', 
  82: 'Private College', 
  83: 'Psychiatric', 
  84: 'Public Facilities Authority', 
  85: 'Public Finance Authority', 
  86: 'Public Power/Utility', 
  87: 'Public University', 
  88: 'Publishing', 
  89: 'Railroad', 
  90: 'Real Estate', 
  91: 'Recreational Facility', 
  92: 'Recycling', 
  93: 'Redevelopment', 
  94: 'Rehabilitation', 
  95: 'Resource Recovery', 
  96: 'Restaurant', 
  97: 'Restaurant', 
  98: 'Retail', 
  99: 'Roadway/Street', 
  100: 'Rubber', 
  101: 'School', 
  102: 'Senior Housing', 
  103: 'Services', 
  104: 'Sewer', 
  105: 'Shipping', 
  106: 'Single', 
  107: 'Solid Waste Disposal', 
  108: 'Special Tax', 
  109: 'Stadium', 
  110: 'State Appropriated Tobacco', 
  111: 'Steel', 
  112: 'Student Loan', 
  113: 'Supermarket', 
  114: 'Tax Allocation', 
  115: 'Tax Increment', 
  116: 'Technology', 
  117: 'Telecommunication', 
  118: 'Tobacco', 
  119: 'Transportation', 
  120: 'Trucking', 
  121: 'Turnpike', 
  122: 'University', 
  123: 'Utility', 
  124: 'Various Purpose', 
  125: 'Water', 
  126: 'Charter School', 
  127: 'Lottery', 
  128: 'Student Housing', 
  129: 'Water & Sewer ', 
  130: 'Essential Workforce Housing Single-Family', 
  131: 'Essential Workforce Housing Multi-Family', 
  132: 'Prepaid Electric', 
  133: 'Prepaid Gas'
}

export const ratingsDict = {
  'AAA': 'AAA', 
  'AA+': 'AA+', 
  'AA': 'AA', 
  'AA-': 'AA-', 
  'A+': 'A+', 
  'A': 'A', 
  'A-': 'A-', 
  'BBB+': 'BBB+', 
  'BBB': 'BBB', 
  'BBB-': 'BBB-', 
  'BB+': 'BB+', 
  'BB': 'BB', 
  'BB-': 'BB-', 
  'B+': 'B+', 
  'B': 'B', 
  'B-': 'B-', 
  'CCC+': 'CCC+', 
  'CCC': 'CCC', 
  'CCC-': 'CCC-', 
  'CC': 'CC', 
  'C': 'C', 
  'D': 'D', 
  'NR': 'No rating', 
  'No rating': 'No rating'
}

export const moodysRatingsDict = {
  'No rating': 'No rating', 
  'NR': 'No rating', 
  'Baa2': 'Baa2', 
  '(P)Aaa': '(P)Aaa', 
  'A2': 'A2', 
  '(P)Aa1': '(P)Aa1', 
  'A1': 'A1', 
  'Baa1': 'Baa1', 
  'Baa3': 'Baa3', 
  'Ba2': 'Ba2', 
  'MIG1': 'MIG1', 
  'WR': 'Withdrawn Rating', 
  'Caa1': 'Caa1', 
  'B2': 'B2', 
  'Caa2': 'Caa2', 
  'Aa2': 'Aa2', 
  'B3': 'B3', 
  'A': 'A', 
  'Aaa': 'Aaa', 
  'Ba1': 'Ba1', 
  'B1': 'B1', 
  'C': 'C', 
  'Aa3': 'Aa3', 
  'A3': 'A3', 
  'Caa3': 'Caa3', 
  'Ba3': 'Ba3', 
  'Aa1': 'Aa1'
}