/*
 * @Author: Gil Shulman 
 * @Date: 2021-10-11 
 * @Last Modified by: Gil Shulman
 * @Last Modified time: 2022-11-17 
 */

import React from "react"; 
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from 'react-bootstrap/Spinner';

function TabsSearchForm({searchValues, set, tradeType, onSubmit, isPricing}) {    //props should include func and states

    return(
        <>
            <Form onSubmit={onSubmit}>
                <Row>
                    <Col> 
                        <Form.Group class="mb-3">
                            <Form.Label class="font-weight-light" size="sm" for="cusip">CUSIP: </Form.Label>
                            <Form.Control required minLength="8" maxLength="9" name="cusip" id="cusip" value={searchValues.cusip.toUpperCase()}  onChange={set('cusip')}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group class="mb-3">
                            <Form.Label class="font-weight-light" size="sm">Trade Amount (thousands): </Form.Label>
                            <InputGroup>    
                                <InputGroup.Text>$ (k)</InputGroup.Text>
                            <Form.Control placeholder="Dollar Amount" type="number" required min="5" max="10000" name="amount" value={searchValues.amount} onChange={set('amount')}/>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group class="mb-3">
                            <Form.Label class="font-weight-light" size="sm">Trade Type: </Form.Label> 
                            <Form.Select required name="tradeType" value={searchValues.tradeType} onChange={set('tradeType')}>
                                {tradeType.map((o) => (<option key={o.key} value={o.key}>{o.text}</option>))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col className="align-self-center">
                        {isPricing?<Button className="btn btn-primary my-2" disabled><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> Pricing... </Button>:<Button className="btn btn-primary my-2" type="submit">Get YTW and Price</Button>}
                    </Col>
                </Row>
            
            {/* <p class="font-weight-light">Date: {searchValues.date} Time: {searchValues.time}</p>
            <Form.Control disabled={true} size="sm" type="date" name="date" value={searchValues.date} hidden/>
            <Form.Control disabled={true} size="sm" type="time" name="time" value={searchValues.time} hidden/> */}
                
            </Form>
        </>  
    );
}

export default TabsSearchForm;