/*
 * @Author: Gil Shulman
 * @Date: 2022-11-10
 * @Last Modified by: Mitas Ray
 * @Last Modified time: 2024-06-13
 */
import React from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
// import Placeholder from 'react-bootstrap/Placeholder'
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FiccCurveHistory from './pricing/ficcCurveHistory';
import SimilarBonds from './pricing/similarBonds';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPrice, uploadFile, getSimilarBonds } from '../services/priceService';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Tooltip } from 'react-tooltip';    // tooltip is used to have a text bubble pop up when hovering over an element

import { statesDict, purposeClassDict, ratingsDict, moodysRatingsDict } from './pricing/relatedVarDict';

import FONT_SIZE from './pricing/globalVariables';

import TabsSearchForm from './tabsCusipSearchForm';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import moment from 'moment-timezone';
import NavBarTop from './navBarTop';

export function yearMonthDayToMonthDayYear(dateString) {    // assumes that the date is in format YYYY-mm-dd
    if (typeof dateString === 'undefined') return 'NC';    // handles call date being undefined; TODO: put this logic elsewhere since this function should be general enough to handle any date (not just call date)
    var dateStringSplit = dateString.split('-');
    return dateStringSplit[1] + '-' + dateStringSplit[2] + '-' + dateStringSplit[0];
}

function Pricing(props) {
    let dt = moment.tz('America/New_York').format('YYYY-MM-DD HH:mm');

    const loggedOutMessage = 'You have been logged out due to a period of inactivity. Refresh the page!';

    const nav = useNavigate();
    const currentDate = dt.substring(0, 10);    // change currentDate back to dt.substr(0,10) as soon as the model is ready:
    const currentTime = dt.substring(11);

    const [stateToken, setStateToken] = useState('');
    const [loadingMessage, setLoadingMessage] = useState();
    const [key, setKey] = useState('pricing');    // used to show which panel we are looking at
    const [predictedPrice, setPredictedPrice] = useState('');
    const [calcMethod, setCalcMethod] = useState('');
    const [ytw, setYtw] = useState('');
    const [usedDollarPriceModel, setUsedDollarPriceModel] = useState(false);
    const [reasonForUsingDollarPriceModel, setReasonForUsingDollarPriceModel] = useState('');    // used to explain to the user why we use the dollar price model
    
    const [userEmail, setUserEmail] = useState('');
    const [open, setOpen] = useState(false);
    
    const [isPriceHidden, setIsPriceHidden] = useState(true);    // boolean argument that hides the predicted price, predicted yield to worst, calculation date, maturity date row at the top which displays our prediction
    const [isRelatedLoading, setIsRelatedLoading] = useState(false);
    const [noRelatedBondsFound, setNoRelatedBondsFound] = useState(true);
    const [similarBondsSearchHasRun, setSimilarBondsSearchHasRun] = useState(false);
    const [isBatchProcessing, setIsBatchProcessing] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(true);     // boolean argument determining whether the related trade search has been run yet used to populate the table if not run yet
    const [isPricing, setIsPricing] = useState(false);

    const [similarBondsRes, setSimilarBondsRes] = useState([]);
    const [tradeHistory, setTradeHistory] = useState([]);
    const [file, setFile] = useState();
    const defaultCusip = '64971XQM3';

    const defaultMinCoupon = 0;    // in hundreds due to the scale value of 100 in MultiRangeSlider for coupon
    const defaultMaxCoupon = 1000;    // in hundreds due to the scale value of 100 in MultiRangeSlider for coupon
    const defaultMinMaturityYear = 2023;
    const defaultMaxMaturityYear = 2123;
    
    const defaultQuantity = 500;    // in thousands NOTE: this needs to match `DEFAULT_QUANTITY` in `app_engine/demo/server/resources.py`
    const defaultTradeType = 'S';    // NOTE: this needs to match `DEFAULT_TRADE_TYPE` in `app_engine/demo/server/resources.py`
    const [cusipForDisplay, setCusipForDisplay] = useState(defaultCusip);
    const [displayTextForYtw, setDisplayTextForYtw] = useState('Worst');    // text that fills in the blank: yield to _
    const [displayPriceForYtw, setDisplayPriceForYtw] = useState(100);    // displays the redemption value for the associated redemption date on the front end NOTE: this needs to match `REDEMPTION_VALUE_AT_MATURITY` in `app_engine/demo/server/modules/finance.py`
    const [searchValues, setSearchValues] = useState({cusip: defaultCusip, 
                                                      amount: defaultQuantity, 
                                                      tradeType: defaultTradeType, 
                                                      date: currentDate, 
                                                      time: currentTime, 
                                                      token: ''});
    const [batchValues, setBatchValues] = useState({quantity: defaultQuantity, 
                                                    tradeType: defaultTradeType});

    const [searchCusipMaturityDate, setSearchCusipMaturityDate] = useState('');
    const [searchCusipNextCallDate, setSearchCusipNextCallDate] = useState('');

    const defaultDesc = '';
    const defaultRelatedSearchVal = {desc: defaultDesc, 
                                     minCoupon: defaultMinCoupon, 
                                     maxCoupon: defaultMaxCoupon, 
                                     minMaturityYear: defaultMinMaturityYear, 
                                     maxMaturityYear: defaultMaxMaturityYear, 
                                    //  minCalculationYear: '', 
                                    //  maxCalculationYear: '', 
                                     radio: 'previous_day', 
                                     issuerChoice: 'any_issuer'
                                    //  amount: 100    // default value of 100k minimum trade amount
                                    };
    const [relatedSearchVal, setRelatedSearchVal] = useState(defaultRelatedSearchVal);
    
    const [referenceFeatures, setReferenceFeatures] = useState({});    // display relevant reference of the newly priced bond

    // when modifying `tradeType` below, also modify `tradeTypeDict` in `pricing/relatedVarDict.js`
    const tradeType = [{key: 'D', text: 'Inter-Dealer'}, 
                       {key: 'P', text: 'Bid Side'},    // 'Purchase from Customer', 'Customer sell', 'Dealer purchase'
                       {key: 'S', text: 'Offered Side'}];    // 'Sale to Customer', 'Customer buy', 'Dealer sell'

    const dollarPriceModelDisplayText = {'missing_or_negative_yields': ['Missing or negative yields reported', 'We do not provide an evaluated yield since previous MSRB reported yields for this CUSIP are missing or negative.'], 
                                         'adjustable_rate_coupon': ['Adjustable rate coupon', 'For adjustable rate coupon, we do not yet display yield. Yield to conversion date coming soon!'], 
                                         'pac_bond': ['Yield to average life coming soon', 'We are developing an option to display yield to average life for Planned Amortization Class (PAC) bonds.']}    // must have the same key and the second item in the value must much the corresponding value of as `DOLLAR_PRICE_MODEL_DISPLAY_TEXT` in `server/modules/finance.py`

    function handleChange(event) {
        setFile(event.target.files[0]);
    }

    function getAuthenticationToken() {
        const auth = getAuth();
        const user = auth.currentUser;
        user.getIdToken(true)
            .then(function (token) {
                setStateToken(token);
                setUserEmail(user.email);
                return token;
            })
            .catch(function(error) {
                console.log(error);
                throw new Error(loggedOutMessage);
            });
    }

    function getDisplayTextForYtw(displayText) {
        // For refund, we want to display 'yield to worst' since 'yield to refund' is not correct terminology
        if (displayText === 'Refund') { return 'Worst'; } 
        else { return displayText; }
    }

    function getDisplayTextForYtwDate(displayText) {
        if (displayText === 'Worst') { return 'Yield to Worst'; }    // 'Worst date' is not meaningful, but 'Call date' is; this line changes 'Worst date' to 'Yield to worst date'
        else { return displayText; }
    }

    function setPricingBlank() {
        setYtw('');
        setUsedDollarPriceModel(false);
        setReasonForUsingDollarPriceModel('');
        setCalcMethod('');
        setPredictedPrice('');
        setOpen(false);
        setIsPriceHidden(true);
    }

    function setBlank() {
        setPricingBlank();
        setSimilarBondsRes([]);
        setIsRelatedLoading(false);
        setIsPricing(false);
        setIsBatchProcessing(false);
        setTradeHistory([]);
        setRelatedSearchVal({desc: '', 
                             coupon: '', 
                            //  minMaturityYear: '', 
                            //  maxMaturityYear: '', 
                            //  minCalculationYear: '', 
                            //  maxCalculationYear: '', 
                             radio: 'previous_day', 
                             issuerChoice: 'any_issuer'
                            //  amount: 100
                            });
        setReferenceFeatures({});
    }

    async function fetchRelatedWithoutError(cusip, 
                                            predicted_yield, 
                                            predicted_price, 
                                            // coupon, 
                                            minCoupon, 
                                            maxCoupon, 
                                            state, 
                                            purposeClass, 
                                            // purposeSubClass, 
                                            desc, 
                                            rating, 
                                            moodysRating, 
                                            minMaturityYear, 
                                            maxMaturityYear, 
                                            // minCalculationYear, 
                                            // maxCalculationYear, 
                                            amount, 
                                            realtime, 
                                            issuerChoice, 
                                            userTriggered) {
        setSimilarBondsRes([]);
        // let response = ''
        getAuthenticationToken();
        return await getSimilarBonds(stateToken, 
                                    cusip, 
                                    predicted_yield, 
                                    predicted_price, 
                                //  coupon, 
                                    minCoupon, 
                                    maxCoupon, 
                                    state, 
                                    purposeClass, 
                                    //    purposeSubClass, 
                                    desc, 
                                    rating, 
                                    moodysRating, 
                                    minMaturityYear, 
                                    maxMaturityYear, 
                                    //    minCalculationYear, 
                                    //    maxCalculationYear, 
                                    amount, 
                                    realtime, 
                                    issuerChoice, 
                                    userTriggered);
        // return response
    }

    async function fetchRelated(cusip, 
                                predicted_yield, 
                                predicted_price, 
                                // coupon, 
                                minCoupon, 
                                maxCoupon,
                                state, 
                                purposeClass, 
                                // purposeSubClass, 
                                desc, 
                                rating,  
                                moodysRating, 
                                minMaturityYear, 
                                maxMaturityYear, 
                                // minCalculationYear, 
                                // maxCalculationYear, 
                                amount, 
                                realtime, 
                                issuerChoice, 
                                userTriggered) {
        setIsRelatedLoading(true);
        try {
            const response = await fetchRelatedWithoutError(cusip, 
                                                            predicted_yield, 
                                                            predicted_price, 
                                                            // coupon, 
                                                            minCoupon, 
                                                            maxCoupon, 
                                                            state, 
                                                            purposeClass, 
                                                        //  purposeSubClass, 
                                                            desc, 
                                                            rating, 
                                                            moodysRating, 
                                                            minMaturityYear, 
                                                            maxMaturityYear, 
                                                        //  minCalculationYear, 
                                                        //  maxCalculationYear, 
                                                            amount, 
                                                            realtime, 
                                                            issuerChoice, 
                                                            userTriggered);
            console.log('inside fetchRelated');
            if (typeof response === 'undefined') throw new Error(loggedOutMessage);
            if ((typeof response === 'object') && ('error' in response)) throw new Error(response['error']);
            setSimilarBondsSearchHasRun(true);
            setSimilarBondsRes(response);
            setIsFirstTime(false);
        } catch (error) {
            if ((typeof error === 'undefined') || (typeof error === 'boolean')) { alert('Error finding trades for similar bonds'); }    // this case is when an error is raised in the server code
            else { alert(error.message); }
        } finally {
            setIsRelatedLoading(false);
        }
    }

    async function onFileUpload(event) {
        event.preventDefault();
        if (typeof file === 'undefined') {
            alert('No file was uploaded');
            return undefined;    // do not proceed further in this function
        }

        setIsBatchProcessing(true);
        getAuthenticationToken();

        const formData = new FormData();
        formData.append('file', file);
        formData.append('access_token', stateToken);
        formData.append('amount', batchValues['quantity']);
        formData.append('tradeType', batchValues['tradeType']);

        try {
            const response = await uploadFile(formData);

            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create 'a' HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'preds.csv');    // or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up 'a' element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch (error) {
            alert('Batch Pricing Error');
        } finally {
            setIsBatchProcessing(false);
        }
    }

    function set(name) {
        return function ({ target: { value } }) {
            setSearchValues(oldSearchValues => ({ ...oldSearchValues, [name]: value }));
        };
    }

    function setBatch(name) {
        return function ({ target: { value } }) {
            setBatchValues(oldBatchValues => ({ ...oldBatchValues, [name]: value }));
        };
    }

    const setRelatedDict = (newValues) => {
        setRelatedSearchVal({...relatedSearchVal, ...newValues});
    };

    function updateData(content) {
        try {
            setOpen(true);
            setPredictedPrice(content.price);
            setYtw(content.ficc_ytw);

            if (content.model_used == 'dollar_price') {
                setUsedDollarPriceModel(true);
                setReasonForUsingDollarPriceModel(content.reason_for_using_dollar_price_model);
            } else {
                setUsedDollarPriceModel(false);
                setReasonForUsingDollarPriceModel('');
            }

            setCalcMethod(content.calc_date);
            setTradeHistory(content.previous_trades_features);
            setSearchCusipMaturityDate(content.maturity_date);
            setSearchCusipNextCallDate(content.next_call_date);
            setDisplayTextForYtw(content.display_text_for_ytw);
            setDisplayPriceForYtw(content.display_price);

            return [content.incorporated_state_code, content.purpose_class, content.purpose_sub_class, content.coupon, content.rating, content.moodys_long, content.security_description, content.ficc_ytw, content.price, content.issue_date];
  
        } catch (error) {
            console.log('inside updateData');
            // setLoadingMessage('Error:' + content['ERROR'] + '. Try refreshing the browser!')
            setLoadingMessage(loggedOutMessage);
            setPricingBlank();
        }
    }

    async function fetchPriceWithoutError() {
        return await getPrice(stateToken, searchValues.cusip, searchValues.tradeType, searchValues.amount, searchValues.date, searchValues.time);
    }

    async function fetchPrice() {
        setIsPriceHidden(true);
        setIsPricing(true);
        setLoadingMessage('Priced at ' + (new Date()).toLocaleString());

        try {
            const response = await fetchPriceWithoutError();
            console.log('inside fetchPrice');
            if (typeof response === 'undefined') throw new Error(loggedOutMessage);
            if ((typeof response === 'object') && ('error' in response)) throw new Error(response['error']);
            var [incorporated_state_code, purpose_class, purpose_sub_class, coupon, rating, moodysRating, desc, predicted_yield, predicted_price, issue_date] = updateData(response[0]);
            if (!(incorporated_state_code in statesDict)) incorporated_state_code = undefined;    // makes sure the related trades search goes through without filtering on the incorporated state code
            setRelatedDict({'state': incorporated_state_code, 'purposeClass': purpose_class, 'purposeSubClass': purpose_sub_class, 'coupon': coupon, 'rating': rating, 'desc': defaultDesc});    // changing to `defaultDesc` so that whenever we run a similar bonds query with a new CUSIP, we do not persist with the old description that may have been used with a previous search
            setReferenceFeatures({'state': incorporated_state_code, 'purposeClass': purpose_class, 'purposeSubClass': purpose_sub_class, 'coupon': coupon, 'rating': rating, 'moodysRating': moodysRating, 'desc': desc, 'datedDate': issue_date});
            setIsPricing(false);
            setIsPriceHidden(false);
            return [incorporated_state_code, purpose_class, purpose_sub_class, coupon, rating, moodysRating, desc, predicted_yield, predicted_price];
        } catch (error) {
            if ((typeof error === 'undefined') || (typeof error === 'boolean')) { alert('Pricing error'); }    // this case is when an error is raised in the server code
            else { alert(error.message); }
            setSimilarBondsSearchHasRun(false);    // go back to initial state
            setBlank();
            throw error;    // need to throw this error in order to correctly propogate up the call stack (e.g., onSubmit requires that this throws an error)
        }
    }

    function redirectToLogin() {
        nav('/login');
    }

    async function onSubmit(event) {
        event.preventDefault();
        setPricingBlank();
        getAuthenticationToken();
       

 searchValues.cusip = searchValues.cusip.toUpperCase();    // fixes the copy and paste issue where a CUSIP with lowercase characters can be copy and pasted into the search box, and although in the search box it is capitalized, a lowercase string is passed into the server causing a pricing error
        const refreshRelatedTrades = isFirstTime || searchValues.cusip !== cusipForDisplay;    // determine whether we need to refresh the related trades search; don't refresh the related trades search if the only changes are trade type and quantity when pricing
        if (refreshRelatedTrades) setBlank();
        setCusipForDisplay(searchValues.cusip);
        try {
            const [incorporated_state_code, purpose_class, purpose_sub_class, coupon, rating, moodysRating, desc, predicted_yield, predicted_price] = await fetchPrice();    // no longer using `purpose_sub_class` to filter on related trades
            
            if (refreshRelatedTrades) {
                fetchRelated(searchValues.cusip, 
                             predicted_yield, 
                             predicted_price, 
                             // coupon, 
                             relatedSearchVal.minCoupon, 
                             relatedSearchVal.maxCoupon, 
                             incorporated_state_code, 
                             purpose_class, 
                             // purpose_sub_class, 
                             defaultDesc,    // changing to `defaultDesc` so that whenever we run a similar bonds query with a new CUSIP, we do not persist with the old description that may have been used with a previous search
                             rating, 
                             moodysRating, 
                             relatedSearchVal.minMaturityYear, 
                             relatedSearchVal.maxMaturityYear, 
                             // 2023, 
                             // 2123, 
                             relatedSearchVal.amount, 
                             relatedSearchVal.radio, 
                             relatedSearchVal.issuerChoice, 
                             false);
            }
        } catch (error) {
            setSimilarBondsSearchHasRun(false);    // go back to initial state
            setBlank();
        }
    }

    useEffect(() => {
        const auth = getAuth();
    
        // If no stored token exists, handle the authentication process as before
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken(true).then((token) => {
                    setStateToken(token);
                    setUserEmail(user.email);
                });
            } else {
                redirectToLogin();
            }
        });
    }, []);

    return (
        <Container fluid class='flex' className='justify-content-center' style={{ fontSize: FONT_SIZE }}>
        <div>
            <Tooltip />    {/* need to declare here in order to work when using `Tooltip` later */}
            <NavBarTop message={loadingMessage} userEmail={userEmail}/>
            <Tabs id='controlled-tabs' activeKey={key} onSelect={(k) => setKey(k)} className='mb-3'>
                <Tab eventKey='pricing' title='Individual Pricing'>
                    <Card xs='auto'>
                        <Card.Body>
                            <TabsSearchForm searchValues={searchValues} set={set} tradeType={tradeType} onSubmit={onSubmit} isPricing={isPricing} />    
                            <Row>
                                <Col>
                                    {isPriceHidden? '':
                                        <Table>
                                        <thead>
                                            <tr>
                                                <th>Evaluated Price (%)</th>
                                                <th>Evaluated Yield to {getDisplayTextForYtw(displayTextForYtw)} (%)</th>
                                                {usedDollarPriceModel ? null:<th>{getDisplayTextForYtwDate(displayTextForYtw)} Date and Price (%)</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><strong><span class='highlight'>{predictedPrice}</span></strong></td>
                                                <td>{usedDollarPriceModel ?
                                                    (<strong><Tooltip id='my-tooltip' /><span class='highlight' data-tooltip-id='my-tooltip' data-tooltip-content={dollarPriceModelDisplayText[reasonForUsingDollarPriceModel][1]}>{dollarPriceModelDisplayText[reasonForUsingDollarPriceModel][0]}</span></strong>)    // index 0 corresponds to the short message, index 1 corresponds to the long message
                                                    :(<strong><span class='highlight' >{ytw}</span></strong>)
                                                    }
                                                </td>
                                                {usedDollarPriceModel ? null:<td>{calcMethod + ' @ ' + displayPriceForYtw.toString()}</td>}
                                            </tr>
                                        </tbody>
                                        </Table>
                                    }
                                    <Collapse in={open}>
                                    <div>
                                    <br></br>
                                    <h5>Key reference features for {cusipForDisplay}</h5>
                                    </div>
                                    </Collapse>
                                    {isPriceHidden? '':
                                        // <div><h5>Aggregate spreads for {cusipForDisplay}; click a row to expand daily trades</h5></div>
                                        <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>State</th>
                                                <th>Dated Date</th>
                                                <th>Moody's</th>
                                                <th>S&P</th>
                                                <th>Purpose Class</th>
                                                <th>Description</th>
                                                <th>Coupon</th>
                                                <th>Maturity Date</th>
                                                <th>Call Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{(referenceFeatures.state in statesDict) ? statesDict[referenceFeatures.state] : 'None'}</td>
                                                <td>{yearMonthDayToMonthDayYear(referenceFeatures.datedDate)}</td>
                                                <td>{moodysRatingsDict[referenceFeatures.moodysRating]}</td>
                                                <td>{ratingsDict[referenceFeatures.rating]}</td>
                                                <td>{(referenceFeatures.purposeClass === 0) ? 'No purpose class reported' : purposeClassDict[referenceFeatures.purposeClass]}</td>
                                                <td style={{ width: '25%' }}>{referenceFeatures.desc}</td>
                                                <td>{+parseFloat(referenceFeatures.coupon).toFixed(3)}</td>
                                                <td>{yearMonthDayToMonthDayYear(searchCusipMaturityDate)}</td>
                                                <td>{yearMonthDayToMonthDayYear(searchCusipNextCallDate)}</td>
                                            </tr>
                                        </tbody>
                                        </Table>
                                        // the `+` character preceding `parseFloat(...)` converts the string returned from `.toFixed(...)` to a number to remove trailing 0's
                                    }
                                    {isPriceHidden ? '' : <FiccCurveHistory data={tradeHistory} cusipForDisplay={cusipForDisplay}/>}
                                    {<br></br>}
                                    {<h5>Recent trades; click a row to expand trades for that CUSIP</h5>}
                                    <SimilarBonds getAuthenticationToken={getAuthenticationToken} relatedSearchVal={relatedSearchVal} setRelatedSearchVal={setRelatedSearchVal} similarBondsRes={similarBondsRes} fetchRelated={fetchRelated} isPricing={isPricing} setIsRelatedLoading={setIsRelatedLoading} isRelatedLoading={isRelatedLoading} noRelatedBondsFound={noRelatedBondsFound} setNoRelatedBondsFound={setNoRelatedBondsFound} similarBondsSearchHasRun={similarBondsSearchHasRun} setSimilarBondsSearchHasRun={setSimilarBondsSearchHasRun} searchValCusip={searchValues.cusip} predictedYield={ytw} predictedPrice={predictedPrice}/>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Tab>
                <Tab eventKey='batch' title='Batch Pricing'>
                    <Card xs='auto'>
                    <Card.Body>
                    <div>
                        <Form onSubmit={onFileUpload}>
                            <Form.Group controlId='formFile' className='mb-3'>
                                <Row><Col><Form.Label>Upload a CSV file with a list of CUSIPs (where each CUSIP is on a separate row). Optionally, enter a trade amount (in thousands) in the second column and a trade type in the third column, corresponding to a particular CUSIP. If no trade amount is entered, then the trade amount will default to the value entered in the box below. The trade amount will automatically be bounded between 5 (corresponding to 5k) and 10000 (corresponding to 10M). If entering a trade type, put the letter P to mean Bid Side, the letter S to mean Offered Side, or the letter D to mean Inter-Dealer. If no trade type is entered, then the trade type will default to the value entered in the box below.</Form.Label></Col></Row>
                                <Row>
                                    <Col>
                                    <Form.Label class='font-weight-light' size='sm'>CSV File: </Form.Label>
                                    <Form.Control type='file' onChange={handleChange} />
                                    </Col>
                                    
                                    <Col>
                                    <Form.Group class='mb-3'>
                                    <Form.Label class='font-weight-light' size='sm'>Trade Amount (thousands): </Form.Label>
                                    <InputGroup>    
                                        <InputGroup.Text>$ (k)</InputGroup.Text>
                                    <Form.Control placeholder='Dollar Amount' type='number' required min='5' max='10000' name='amount' value={batchValues.quantity} onChange={setBatch('quantity')}/>
                                    </InputGroup>
                                    </Form.Group>
                                    </Col>
                                    
                                    <Col>
                                    <Form.Group class='mb-3'>
                                        <Form.Label class='font-weight-light' size='sm'>Trade Type: </Form.Label>
                                        <Form.Select required name='

tradeType' value={batchValues.tradeType} onChange={setBatch('tradeType')}>
                                            {tradeType.map((o) => (<option key={o.key} value={o.key}>{o.text}</option>))}
                                        </Form.Select>
                                    </Form.Group>
                                    </Col>
                                    <Col className='align-self-center'>
                                        {isBatchProcessing?<Button className='btn btn-primary my-2' disabled><Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true'/> Pricing... </Button>:<Button className='btn btn-primary my-2' type='submit'>Submit</Button>}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>
                        <br></br>    {/* this is for an extra space */}
                        <h5>CSV Formatting Instructions:</h5>
            
                        <Table striped bordered size='sm' className='mt-2'>
                            <thead>
                                <tr>
                                    <th>CUSIP</th>
                                    <th>Trade Amount (in thousands)</th>
                                    <th>Trade Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>801315LS9</td>
                                    <td>20</td>
                                    <td>P</td>
                                </tr>
                                <tr>
                                    <td>228130GF1</td>
                                    <td>50</td>
                                    <td>D</td>
                                </tr>
                                <tr>
                                    <td>751622RN3</td>
                                    <td>20</td>
                                    <td>S</td>
                                </tr>
                                <tr>
                                    <td>120827DN7</td>
                                    <td>300</td>
                                    <td>S</td>
                                </tr>
                                <tr>
                                    <td>120827DN7</td>
                                    <td>10</td>
                                    <td>S</td>
                                </tr>
                                <tr>
                                    <td>120827DN7</td>
                                    <td>25</td>
                                    <td>S</td>
                                </tr>
                                <tr>
                                    <td>915183YR2</td>
                                    <td>15</td>
                                    <td>P</td>
                                </tr>
                                <tr>
                                    <td>91412GR23</td>
                                    <td>25</td>
                                    <td>S</td>
                                </tr>
                                <tr>
                                    <td>59259RS47</td>
                                    <td>25</td>
                                    <td>D</td>
                                </tr>
                                <tr>
                                    <td>076280HR0</td>
                                    <td>15</td>
                                    <td>S</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    </Card.Body>
                    </Card>
                </Tab>
            </Tabs>
        </div>
      </Container>
    )
}

export default (Pricing);


