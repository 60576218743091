/*
 * @Last Modified by: Mitas Ray
 * @Last Modified time: 2024-05-06
 */
import React from 'react'

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

function NavBarTop({message, userEmail}) {
    function cannotViewPrivateContent(email) {    // used to hide the yield curve and the compliance modules from everyone except those listed in `domainsThatCanViewPrivateContent`
        const domainsThatCanViewPrivateContent = ['ficc.ai']
        for (var idx = 0; idx < domainsThatCanViewPrivateContent.length; idx++) {
            var domain = domainsThatCanViewPrivateContent[idx]
            if (email.toLowerCase().includes('@' + domain)) return false
        }
        return true
    }

    return (
        <Navbar bg='light' expand='lg'>
            <Container fluid>
                <Navbar.Brand href='https://pricing.ficc.ai/pricing'>ficc.ai</Navbar.Brand>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className='me-auto' style={{ marginLeft: '10px'}}>
                        <Nav.Link href='pricing' style={{ marginLeft: '10px', marginRight: '10px'}}>Pricing Engine</Nav.Link>
                        {cannotViewPrivateContent(userEmail) ? '':<Nav.Link href='compliance' style={{ marginLeft: '10px', marginRight: '10px'}}>Compliance</Nav.Link>}
                        {cannotViewPrivateContent(userEmail) ? '':<Nav.Link href='ficcyieldcurve' style={{ marginLeft: '10px', marginRight: '10px'}}>Real Time Yield Curve</Nav.Link>}
                        <Nav.Link href='dailyschoonover' style={{ marginLeft: '10px', marginRight: '10px'}}>Real Time Muni Market Stats</Nav.Link>
                        <Nav.Link href='contactTab' style={{ marginLeft: '10px'}}>Contact</Nav.Link>
                    </Nav>
                    <Navbar.Text class='me-2' style={{marginRight: '10px'}}>{message}</Navbar.Text>
                    <Navbar.Text style={{marginLeft: '10px'}}>
                             Signed in as: <a href='login'>{userEmail}</a>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBarTop