/*
 * @Author: Gil Shulman 
 * @Date: 2021-09-29 13:05:31 
 * @Last Modified by: Mitas Ray
 * @Last Modified time: 2023-11-06
 */
import React from 'react'
import '../App.css'
import Plot from 'react-plotly.js'

function RealtimeYieldCurve({yield_data}) {
    return (
      <Plot
        data={[
          {type: 'line', 
          name: 'Yield Curve',
            x: yield_data.x,
            y :yield_data.yield,
            line: {
              color: 'rgb(153,204,255)',
              width: 4}
          }
        ]}

        layout={{
          showlegend: true,  
          modeBarButtonsToRemove: ['toImage'],
          font: {
            family: 'Helvetica,Arial',
            size: 18,
            color: 'rgb(77,117,158)'
          },
          margin: {
            t: 0
          },
          legend: {
            x: 1, y: 0.1, 
            xanchor: 'right',
            yanchor: 'bottom'
          },
          autosize: true,    // set autosize to rescale
          // title: {text: 'Yield Curve'}, 
          xaxis: {
            rangemode:'normal',
            autorange: true,
            automargin: true,
            title: 'Years to Maturity'
          },
          yaxis: {
            automargin: true,
            title: 'Yield',
            range: [0, 6],
            dtick: 0.5,
            hoverformat: ',.3f'
        }}}
      />
    );
}
export default RealtimeYieldCurve